.noty_theme__bootstrap-v4.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  position: relative;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.noty_theme__bootstrap-v4.noty_bar .noty_body {
  padding: 0.75rem 1.25rem;
}
.noty_theme__bootstrap-v4.noty_bar .noty_buttons {
  padding: 10px;
}
.noty_theme__bootstrap-v4.noty_bar .noty_close_button {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.5;
  background: transparent;
}
.noty_theme__bootstrap-v4.noty_bar .noty_close_button:hover {
  background: transparent;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.75;
}

.noty_theme__bootstrap-v4.noty_type__alert,
.noty_theme__bootstrap-v4.noty_type__notification {
  background-color: #fff;
  color: inherit;
}

.noty_theme__bootstrap-v4.noty_type__warning {
  background-color: #453b07;
  color: #ffffff;
  border-color: #8b760e;
}

.noty_theme__bootstrap-v4.noty_type__error {
  background-color: #491d1d;
  color: #ffffff;
  border-color: #803333;
}

.noty_theme__bootstrap-v4.noty_type__info,
.noty_theme__bootstrap-v4.noty_type__information {
  background-color: #1b5d7e;
  color: #ffffff;
  border-color: #288cbd;
}

.noty_theme__bootstrap-v4.noty_type__success {
  background-color: #29491d;
  color: #ffffff;
  border-color: #478033;
}
